import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, list, loading } from './blog.module.scss';
import { translationKeys } from '../config/translation-keys';
import { POST_INITIAL_CURRENT_PAGE, POST_PAGE_COUNT } from '../config/consts';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IPostCard } from '../models/post.model';
import { ISitePageContext } from '../config/site-page.model';
import { IPagination } from '../models/pagination.model';
import { getNodes } from '../utils/get-nodes';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';
import { useTranslation } from '../hooks/use-translation';
import { usePostCardsContext } from '../contexts/post-cards-context';

import MainLayout from '../layouts/main-layout';
import PostList from '../components/organisms/post-list';

interface IBlogProps {
    readonly data: {
        allPost: IQueryAllResult<IPostCard>;
    };
    readonly pageContext: ISitePageContext;
}

const Blog: React.FC<IBlogProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { allPost } = data;
    const { items: apiPosts, getNextPage, pagination, status } = usePostCardsContext();
    const posts = getNodes(allPost);

    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const breadcrumbs = [getBreadcrumbItem(pageContext, 'label')];

    const showButton = checkIfShowMoreButton(allPost.totalCount, pagination);

    return (
        <MainLayout
            isVisibleHeaderBg={true}
            className={`${layout} ${status === 'loading' ? loading : ''}`}
            SEOProps={{ title: label }}
            breadcrumbs={breadcrumbs}
            areBreadcrumbsDark={true}
        >
            <PostList
                className={list}
                posts={[...posts, ...apiPosts]}
                title={t('news.title')}
                titleProps={{ Tag: 'h1' }}
                cardProps={{ TitleTag: 'h2', LeadTag: 'h3' }}
                showButton={showButton}
                buttonText={t('news.button')}
                buttonProps={{ onClick: getNextPage }}
            />
        </MainLayout>
    );
};

function checkIfShowMoreButton(totalCount = POST_PAGE_COUNT, pagination: IPagination): boolean {
    if (pagination.currentPage <= POST_INITIAL_CURRENT_PAGE) {
        return totalCount > POST_PAGE_COUNT;
    }
    return pagination.currentPage < pagination.pageCount;
}

export const query = graphql`
    query ($language: String!, $limit: Int!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allPost(limit: $limit, sort: { fields: publishedAt, order: DESC }) {
            edges {
                node {
                    ...postCardFields
                }
            }
            totalCount
        }
    }
`;

export default Blog;
